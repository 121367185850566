body {
  background-color: var(--eds-color-grey-6);
}

a {
  color: #111;
  cursor: pointer;
  text-decoration: none;
}

.AppLayout {
  min-height: 100vh;
  display: flex;
}

.pageLayout {
  width: 100vw;
}

.PageContainer {
  padding: 0 56px 0 56px;
}

.floatRight {
  float: right;
}

.ScreenContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}

.ScreenContainer.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.flexContainer {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexItemSpinner {
  padding: 5px;
  width: 100px;
  height: 100px;
  line-height: 150px;
  text-align: center;
}

.flexItem {
  padding: 5px;
  height: 100px;
  line-height: 150px;
  text-align: center;
}

.SideMenuContainer .slide-pane__close {
  display: none;
}

.SideMenuContainer .slide-pane__content {
  padding: 20px;
  background-color: var(--eds-color-grey-6);
}

.SideMenuContainer .navItem {
  flex: 1;
  color: var(--eds-color-grey-1);
  padding: 8px 0;
  margin: 0;
  cursor: pointer !important;
  font-weight: 500;
  border-bottom: 1px solid var(--eds-color-grey-2);
}

.SideMenuContainer .navItem ul {

  margin-top: 10px;
}

.SideMenuContainer .navSubItem {
  margin-left: 16px;
  padding: 1px;
}

.SideMenuContainer .navItem svg {
  float: left;
  margin-right: 10px;
}

.eds-app-header {
  border-bottom: 1px solid var(--eds-color-grey-3);
  .eds-type--subtitle-1 {
    font-weight: 600 !important;
  }
}

ol.numList {
  list-style: auto;
  font-size: 90%;

  li{
    padding-bottom: 15px;
  }
}