@use "~ag-grid-community/styles"as ag;
@include ag.grid-styles((theme: alpine));

.ag-theme-alpine { 
    --ag-foreground-color: #111111;
    --ag-header-foreground-color: #6A6A6A;
    --ag-header-background-color: #fff;
    --ag-font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif, -apple-system, BlinkMacSystemFont;
    .ag-root-wrapper {
        border: var(--ag-borders) rgb(169 169 169 / 25%);
        border-radius: 10px;
    }
    .ag-ltr .ag-cell {
        padding: 10px;
    } 
    .ag-header-cell-text{
        color: var(--ag-header-foreground-color) !important;
        font-size: 15px;
        font-weight: 600;
        white-space: break-spaces;
    }
}

body {
    margin: 0;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif, -apple-system, BlinkMacSystemFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}