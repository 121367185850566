@import '../common/Variables.scss';

.button-group {
  font-size: 16px;
  font-weight: 500;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  padding: 0px 10px 10px 10px !important;
}

.eds-button--icon.eds-button--medium {
  padding-bottom: 10px !important;
}

.search-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .statusFilterGroup {
    padding-top: 24px;

    .statusStyle {
      display: inline-grid;
      border-right: solid 1px black;
      padding: 0px 10px 0px 10px;
    }

    .statusStyle:nth-last-child(1) {
      border: none;
    }

    .eds-button .eds-button--secondary .eds-button--small {
      color: var(--eds-color-grey-1) !important;
    }

    .eds-button .eds-button--primary .eds-button--small {
      color: var(--eds-color-white) !important;
    }

    .eds-button--secondary {
      max-width: 115px;
    }
  }
}

.action-button-group {
  display: flex;
  justify-content: flex-start;
  gap: var(--eds-space-8);
  align-items: center;

  .action-button {
    margin-top: 15px;
  }
}

.x-small {
  .the-x {
    width: 16px;
  }

  font-size: 14px !important;
  padding: 2px 6px !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
  background-color: var(--eds-color-grey-3) !important;
  border: none !important;
  margin-right: 5px;
}

.breadcrumb-button-group {
  display: flex;
  justify-content: flex-start;
  gap: var(--eds-space-8);
  align-items: center;
  margin-bottom: 24px;
  height: 36px;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.sub-filter-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  transition: all 0.5s ease;
  opacity: 1;
  height: 120px;
}

.sub-filter-button-group-active {
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0;
  transition: all 0.5s ease;
}

.sub-filter-button-group > button {
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  color: #6a6a6a;
}

.line-break {
  width: 100%;
}

.preset-buttons {
  margin-right: 5px;
  float: left;
  border: none !important;
}

.preset-buttons-active {
  margin-right: 5px;
  float: left;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2px solid #555 !important;
}

.card-button {
  align-items: none !important;
  margin-top: 20px;
  width: 34%;
  height: 100px;
  border-radius: 0 !important;
  background-color: var(--eds-color-white) !important;
  border: none !important;
}

.card-button-active {
  align-items: none !important;
  margin-top: 20px;
  width: 34%;
  height: 100px;
  border-radius: 0 !important;
  color: var(--eds-color-white) !important;
  background-color: var(--eds-color-black) !important;
  border: 1.5px solid var(--eds-color-black) !important;
}

.card-button-active > span {
  color: var(--eds-color-white) !important;
}

.headercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#dashboard-cancel-button {
  margin-right: 8px;
  background-color: var($cancel-color);
  border: 1.5px solid var($cancel-color);
  color: #fff;
}

#dashboard-confirm-button {
  background-color: var($confirm-color);
  border: 1.5px solid var($confirm-color);
  color: #fff;
}

.eds-button--disabled {
  background-color: var(--eds-color-grey-3) !important;
  border-color: var(--eds-color-grey-3) !important;
  color: var(--eds-color-grey-1) !important;
}

.action-buttons-wrapper {
  margin-left: auto;
}

.statusbar {
  margin-top: -35px;
  z-index: 99999;
  position: absolute;
  margin-left: 10px;
  display: flex;
}

.statusbar-text {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
  margin-top: 2px;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.filter-header-container {
  padding-left: 6%;
  padding-bottom: 20px;
  background-color: #fbfbfb;

  .eds-select .eds-select__control {
    min-height: auto !important;
    min-width: 150px;
  }
  .eds-label {
    position: absolute !important;
    display: block !important;
    background-color: #fff !important;
    margin-top: 5px !important;
    z-index: 2 !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    margin-left: 7px !important;
    padding: 0px 5px !important;
  }
}

.clear-filter-button {
  padding-top: 20px !important;
  padding-left: 3% !important;
}

.eds-text-field__input {
  font: var(--eds-type-body-3) !important;
}

.eds-select .eds-text-field {
  min-height: 52px;
}

.page-container {
  height: calc(100vh - 64px);
  width: 100%;
  padding: 0;

  .dashboard-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
}

.action-failure-modal {
  .eds-modal__header span {
    text-align: center;
  }

  .responses-table {
    width: 100%;
    text-align: left;
    margin-top: 40px;

    thead {
      text-decoration: underline;
    }

    td {
      padding: 8px;
    }
  }
}

.update-transfer-spinner-container {
  background: rgba(0, 0, 0, 0.5);
}

.cell-status {
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  padding: 5px 10px 5px 10px;
}
.cell-confirmed {
  background: var($confirm-color);
}
.cell-factory {
  background: var($factory-color);
}
.cell-sourcing {
  background: var($sourcing-color);
}
.cell-cancelled {
  background: var($cancel-color);
}
.cell-draft {
  background: var($draft-color);
}

.filter-count-btn.eds-button--primary,
.filter-count-btn.eds-button--primary:hover {
  background-color: #111111;
  border: 1.5px solid #111111;
}

.data-grid-without-status-filter {
  display: none;
}
