@import '../common/Variables.scss';

.new-transfer-container {
  .eds-select .eds-select__multi-value__label {
    font: var(--eds-type-legal-1);
    font-weight: 500;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6a6a6a;
    margin-left: 5px;
  }

  .back-icon {
    vertical-align: bottom;
    cursor: pointer;
  }

  #action-buttons {
    margin-right: 8px;
  }

  .state-buttons:nth-child(2) {
    border-radius: 30px 0px 0px 30px;
    margin-right: 8px;
  }

  .state-buttons {
    border-radius: 0px;
    margin-right: 8px;
  }

  .state-buttons.eds-button--disabled {
    background-color: var(--eds-color-grey-3) !important;
    border-color: var(--eds-color-grey-3) !important;
    color: var(--eds-color-grey-1) !important;
  }

  .state-buttons:last-child {
    border-radius: 0px 30px 30px 0px;
    margin-right: 8px;
  }

  #confirm-button {
    background-color: var($confirm-color);
    border: 1.5px solid var($confirm-color);
    color: var(--eds-color-white);
    .eds-button--disabled {
      background-color: #fff;
    }
  }

  #send-to-sourcing-button {
    background-color: var($sourcing-color);
    border: 1.5px solid var($sourcing-color);
    color: var(--eds-color-white);
  }

  #send-to-factory-button {
    background-color: var($factory-color);
    border: 1.5px solid var($factory-color);
    color: var(--eds-color-white);
  }

  #draft-button {
    background-color: var($draft-color);
    border: 1.5px solid var($draft-color);
    color: var(--eds-color-white);
  }

  .new-info-form-container {
    margin-top: 25px;

    .top-hr {
      margin-top: 32px;
    }

    .form-title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 16px;

      .collapse-icon {
        margin-left: 6px;
      }
    }

    .input-groups {
      display: flex;

      .input-group {
        width: 50%;
      }
    }

    .form-input-container {
      margin-top: 22px;
      max-width: 560px;
      .eds-select .eds-select__control {
        min-height: 40px !important;
      }
    }
  }

  .product-info-form-container {

    .top-hr {
      margin-top: 32px;
    }

    .form-title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 16px;

      .collapse-icon {
        margin-left: 6px;
      }
    }

    .input-groups {
      display: flex;

      .input-group {
        width: 50%;
      }
    }

    .form-input-container {
      margin-top: 22px;
      max-width: 560px;
      .eds-select .eds-select__control {
        min-height: 40px !important;
      }
    }
  }

  .factory-info-form-container {

    .top-hr {
      margin-top: 32px;
    }

    .input-groups {
      display: flex;

      .input-group {
        width: 50%;
      }
    }

    .form-input-container {
      margin-top: 24px;
      max-width: 560px;
      .eds-select .eds-select__control {
        min-height: 40px !important;
      }
    }
  }

  .additional-info-form-container {
    margin-bottom: 250px;
    .top-hr {
      margin-top: 32px;
    }

    .form-title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 16px;

      .collapse-icon {
        margin-left: 6px;
      }
    }

    .form-desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
      width: 50%;
    }

    .input-groups {
      display: flex;

      .input-group {
        width: 100%;
      }

      .input-text-group {
        width: 100%;
      }

      .checkbox-inputs {
        margin-top: 10px;
        display: flex;
        .checkbox-input {
          width: 100%;
          span {
            // chekbox white background
            background-color: #ffffff;
          }
        }
      }
    }

    .transfer-reason-desc {
      margin-top: 62px;
      .desc-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }

      .desc-sections {
        display: flex;
        margin-top: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .desc-section {
        width: 153px;
        max-width: 153px;
      }
    }

    .form-input-container {
      margin-top: 24px;
      max-width: 560px;
      .eds-select .eds-select__control {
        min-height: 40px !important;
      }
    }
  }

  .transfer-note-container {
    margin-top: 24px;
    margin-right: 46px;

    display: flex;

    .input-group {
      width: 100%;
    }

    .form-input-container {
      max-width: 1262px;
    }
  }

  .input-label {
    display: block !important;
    font-size: 12px !important;
    margin-left: 7px !important;
    padding: 0px 5px !important;
    font-weight: 500 !important;
  }

  .eds-type--title-6 {
    font-size: 12px !important;
    margin-left: 7px !important;
    padding: 0px 5px !important;
  }

  .eds-text-field {
    margin-top: 0 !important;
  }
}

#spinner {
  position: absolute;
  margin-left: 528px;
  margin-top: 38px;
  z-index: 1;
}

@media (max-width: 1250px) {
  #spinner {
    position: absolute;
    margin-left: 480px;
    margin-top: 38px;
    z-index: 1;
  }
}

@media (max-width: 1150px) {
  #spinner {
    position: absolute;
    margin-left: 380px;
    margin-top: 38px;
    z-index: 1;
  }
}

@media (max-width: 995px) {
  #spinner {
    display: none;
  }
}

.date-picker-input-container {
  .eds-text-field__input {
    background: no-repeat 98% 50% #ffffff;
    background-image: url('../../assets/images/calendar.svg');
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    left: -21px;
  }
}

.Toastify__toast-container {
  width: var(--toastify-toast-max-height) !important;
}

.Toastify__toast--error {
  border: 1px solid var(--eds-color-red-50);
  border-radius: 50px !important;
  background: #fae1e2 !important;
}

.Toastify__toast--error::after {
  content: url('../../assets/images/close.svg'); // Your svg Path
  position: relative;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: -5px;
  padding-top: 7px !important;
}

.Toastify__toast--success {
  border: 1px solid var(--eds-color-green-50) !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
}

.Toastify__toast--success::after {
  content: url('../../assets/images/close.svg'); // Your svg Path
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 725px;
  padding-top: 7px !important;
}

.Toastify__toast--warning {
  border: 1px solid var(--eds-color-orange-50) !important;
  border-radius: 50px !important;
  background: #fadfc5 !important;
}

.Toastify__toast--warning::after {
  content: url('../../assets/images/close.svg'); // Your svg Path
  position: absolute;
  color: var(--eds-color-orange-50);
  font-size: 15px;
  font-weight: 700;
  left: 725px;
  padding-top: 7px !important;
}

.Toastify__toast--info {
  border: 1px solid var(--eds-color-blue-40);
  border-radius: 50px !important;
  background: #9ad0ff !important;
}

.Toastify__toast--info::after {
  // content: url('../../assets/images/close.svg'); // Your svg Path
  position: relative;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: -5px;
  padding-top: 7px !important;
}

.Toastify__toast-body {
  color: #333333;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.Toastify__toast > button > svg {
  display: none;
}

.eds-scrim .eds-modal__content {
  overflow-y: hidden !important;
}

.redirection-link {
  color: blue;
  text-decoration: underline;
}
