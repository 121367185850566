@import '../common/Variables.scss';

.view-transfer-container {
  margin: 0 auto;
  min-width: 1200px;
  padding: 56px;

  .button-group {
    display: flex;
    justify-content: flex-end;
  }

  .tabShow {
    display: block;
  }

  .tabHide {
    display: none;
  }

  .progress-title {
    line-height: 1.5;
    padding-left: 10px;
  }

  .progress-disable {
    color: #b2b2b2;
  }

  .transferProgressBox {
    h5 {
      .eds-icon {
        float: left;
      }
    }
  }
  hr {
    border-top: 1px solid var(--eds-color-grey-2);
  }
  textarea {
    min-height: 126px;
  }
}

#flag-icon::before {
  padding: 15px 10px 10px 0px !important;
  content: url('../../assets/images/flag.svg');
}

.transfer-title {
  padding-top: 25px;
  padding-bottom: 15px;
}

.primary-data {
  .eds-type--body-2 {
    font-weight: 600 !important;
    padding-right: 30px;
    text-align: right;
  }
}

.action-buttons {
  margin-right: 8px;
}

.view-state-buttons:first-child {
  border-radius: 30px 0px 0px 30px;
  margin-right: 8px;
}

.view-state-buttons:nth-child(2) {
  border-radius: 0px;
  margin-right: 8px;
}

.view-state-buttons:only-child {
  border-radius: 30px !important;
  margin-right: 8px !important;
}

.view-state-buttons:last-of-type {
  border-radius: 0px 30px 30px 0px;
  margin-right: 8px;
}

#confirm-button {
  background-color: var($confirm-color);
  border: 1.5px solid var($confirm-color);
  color: var(--eds-color-white);
  .eds-button--disabled {
    background-color: #fff;
  }
}

#send-to-sourcing-button {
  background-color: var($sourcing-color);
  border: 1.5px solid var($sourcing-color);
  color: var(--eds-color-white);
}

#send-to-factory-button {
  background-color: var($factory-color);
  border: 1.5px solid var($factory-color);
  color: var(--eds-color-white);
}

#cancel-transfer-button {
  background-color: var($cancel-color);
  border: 1.5px solid var($cancel-color);
  color: var(--eds-color-white);
}

#draft-button {
  background-color: var($draft-color);
  border: 1.5px solid var($draft-color);
  color: var(--eds-color-white);
}

#status-indicator {
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 3px 2px 7px 1px #b2b2b2;
  font-size: 25px !important;
}

.change-log-container {
  .change-log-table {
    width: 100%;
  }

  .log-field-text,
  .log-o-value-text,
  .log-n-value-text {
    overflow-wrap: break-word;
  }

  .attachments-ol,
  .comments-ol {
    list-style-type: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .attachments-ol {
    padding-left: 18px;
  }
}
